import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"

const AboutUs = () => {
    return (
        <Layout>
            <Seo title="About Us" />
            <div className="container py-3 py-lg-4">
                <div className="row g-0 mb-3">
                    <div className="col border-bottom border-1 border-dark">
                        <h1>About Us</h1>
                    </div>
                </div>

                <div className="row">
                    <div className="card mb-3 border-0">
                        <div className="row gx-3">
                            <div className="col-lg-6 d-flex justify-content-center ">
                                <StaticImage
                                    transformOptions={{ cropFocus: "attention" }}
                                    alt="Sales team"
                                    src="../../../images/other/singapore1.jpg"
                                    width={696}
                                    layout="fixed"
                                />
                            </div>
                            <div className="col-lg-6 ">
                                <div className="card-body h-100 gray-bg d-flex flex-column">
                                    <p>
                                        Delta Corp Bulk Logistics facilitates international trade between worlds major economies as
                                        well as emerging markets. Connecting end users to producers and miners, we extract value along
                                        the supply chain.
                                    </p>
                                    <p>
                                        It has been our goal from the onset to make a difference in the way drybulk and tanker ocean
                                        freight is being traded. Our strong and proven management team, patient capital from our anchor
                                        investors, our pioneering spirit and shared commitment to the values of integrity,
                                        accountability and client focus, will help us fulfill our vision to set new standards of
                                        excellence and customer care within the drybulk industry as top 10 bulk carrier companies.
                                    </p>
                                    <p>
                                        On land we provide full load transport and container services with our own, well maintained,
                                        fleet of trucks and vehicles. Our customers appreciate our hands-on approach where we stand for
                                        cost effective results. We take the time to properly advise our customers with their
                                        transportation needs.
                                    </p>
                                    <p>
                                        Our commitment to the industry and service to our clients is demonstrated by our immediate
                                        global presence from the outset in Ireland, Copenhagen, Geneva, Dubai, Mumbai, Delhi and
                                        Singapore. The Team is a talented team with a mix of seasoned experienced professionals and
                                        young, dynamic operations and freight traders.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default AboutUs
